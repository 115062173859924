import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import SelectField from '@/components/formControl/SelectField';

import { ChildrenCount, CustomerFormProps } from '@/types';
import { getFormKey } from '@/utils/system';
import useTranslatedEnum from '@/hooks/useTranslatedEnum';
import FormCard from '@/components/ui/FormCard';
import { Stack, TextField } from '@mui/material';
import NumberField from '../ui/NumberField';

const ChildrenCountForm: FC<CustomerFormProps> = ({ formKey }) => {
  const { t } = useTranslation();

  const [childrenCount] = useTranslatedEnum('ChildrenCount', ChildrenCount);

  return (
    <FormCard>
      <Stack direction="row" gap={14} flex={1}>
        <Stack flex={1}>
          <NumberField
            fieldName={getFormKey(formKey, 'child_benefit_eligible_children')}
            label={t(
              'customer:newCustomer.form.childBenefitEligibleChildren.label',
            )}
          />
        </Stack>
        <Stack flex={1}>
          <SelectField
            items={childrenCount}
            fieldName={getFormKey(
              formKey,
              'child_benefit_non_eligible_children',
            )}
            label={t(
              'customer:newCustomer.form.childBenefitNonEligibleChildren.label',
            )}
          />
        </Stack>
      </Stack>
    </FormCard>
  );
};

export default ChildrenCountForm;
