import { SXStyleSheet } from '@/utils';
import { typographyClasses } from '@mui/material';

const style = SXStyleSheet.create({
  container: {
    height: '44px',
    borderRadius: '8px',
    textTransform: 'none',
    width: '100%',
    [`&.Mui-disabled`]: {
      backgroundColor: (theme) => theme.palette.grey[50],
      cursor: 'not-allowed',
    },
  },
  autoWidthContainer: {
    width: 'fit-content',
  },
  progress: {
    color: 'primary.contrastText',
  },
  iconContainer: {
    mr: 2,
  },
  primary: {
    backgroundColor: 'primary.main',
  },
  secondary: {
    backgroundColor: 'secondary.main',
  },
  tertiary: (theme) => ({
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.divider}`,
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
    [`& .${typographyClasses.button}`]: {
      color: theme.palette.text.primary,
    },
  }),
  outlined: (theme) => ({
    backgroundColor: 'background.paper',
    [`& .${typographyClasses.button}`]: {
      color: 'text.secondary',
    },
    border: `1px solid ${theme.palette.divider}`,
  }),
  normal: {
    height: '44px',
  },
  small: {
    height: '40px',
  },
  large: {
    height: '56px',
  },
  disabled: {
    cursor: 'not-allowed !important',
    pointerEvents: 'auto', 
    '&.Mui-disabled': {
      cursor: 'not-allowed !important',
      pointerEvents: 'auto',
    },
  },
});

export default style;
