import Logo from './Logo.png';
import Logo2x from './Logo2x.png';
import Insurance from './Insurance.png';
import Analytics from './Analytics.png';
import SternLogo from './SternLogo.png';
import AnalyticsBarChart from './AnalyticsBarChart.png';
import AnalyticsPieChart from './AnalyticsPieChart.png';
import Roadmap from './Roadmap.png';
import Tagesshau from './Tagesshau.png';
import RoadImage from './RoadImage.jpg';
import NegativeCurve from './NegativeCurve.png';
import PositiveCurve from './PositiveCurve.png';
import KinderCards from './KinderCards.png';
import AnalyticsSection from './AnalyticsSection.png';
import Focus from './Focus.png';
import InterestRate from './InterestRate.png';
import DrKlein from './DrKlein.png';
import KLVGraph from './KLVGraph.png';
import KapitalRoad from './KapitalRoad.png';
import Individuele from './Individuele.png';
import Immobile from './Immobile.png';
import Umzug from './Umzug.png';
import Regelmasige from './Regelmasige.png';
import Renovierung from './Renovierung.png';
import Auto from './Auto.png';
import RoadCalculator from './RoadCalculator.png';
import EstgImage from './EstgImage.png';
import RoadSectionItems from './RoadSectionItems.png';
import Formula from './Formula.png';

const Image = {
  Logo,
  Logo2x,
  Insurance,
  Analytics,
  SternLogo,
  AnalyticsBarChart,
  AnalyticsPieChart,
  Roadmap,
  Tagesshau,
  RoadImage,
  NegativeCurve,
  PositiveCurve,
  KinderCards,
  AnalyticsSection,
  Focus,
  InterestRate,
  DrKlein,
  KLVGraph,
  KapitalRoad,
  Individuele,
  Immobile,
  Umzug,
  Regelmasige,
  Renovierung,
  Auto,
  RoadCalculator,
  EstgImage,
  RoadSectionItems,
  Formula,
};

export type ImageType = keyof typeof Image;

export default Image;
