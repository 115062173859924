import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { FC } from 'react';

import style from './style';
import { IconType } from '@/assets/icons';
import Icon from '@/components/ui/Icon';
import { SXStyleSheet } from '@/utils';

export type CTAButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'outlined';

export interface CTAButtonProps {
  label?: string;
  onClick?: () => void;
  variant?: CTAButtonVariant;
  disabled?: boolean;
  loading?: boolean;
  icon?: IconType;
  size?: 'small' | 'normal' | 'large';
  fixedWidth?: number;
  autoWidth?: boolean;
}

const CTAButton: FC<CTAButtonProps> = ({
  label,
  onClick,
  variant = 'primary',
  loading,
  icon,
  size = 'normal',
  fixedWidth,
  autoWidth,
  disabled,
}) => {
  return (
    <Button
      sx={SXStyleSheet.merge(
        style.container,
        style[variant],
        style[size],
        !!fixedWidth && { width: fixedWidth },
        autoWidth && style.autoWidthContainer,
        disabled && style.disabled,
      )}
      disableElevation
      disableRipple
      disableFocusRipple
      disableTouchRipple
      onClick={onClick}
      variant="contained"
      disabled={disabled}
      color="inherit"
    >
      {!!icon && (
        <Stack sx={SXStyleSheet.merge(!!label && style.iconContainer)}>
          <Icon icon={icon} />
        </Stack>
      )}
      {loading ? (
        <CircularProgress size={20} sx={style.progress} />
      ) : label ? (
        <Typography variant="button" color="primary.contrastText">
          {label}
        </Typography>
      ) : null}
    </Button>
  );
};

export default CTAButton;
