import Image from '@/components/ui/Image';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const styles = {
  root: {
    height: '100%',
    width: '100%',
  },
  contentBox: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: 2,
    padding: 6,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 2,
    flex: 1,
  },
  formulaWrapper: {
    backgroundColor: '#E6F3FA',
    borderRadius: 2,
    overflow: 'hidden',
  },
  formulaHeader: {
    backgroundColor: '#00ABEA',
    height: '52px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formulaImage: {
    padding: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '62px',
  },
  bottomBox: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: 2,
    display: 'flex',
    flexDirection: 'column',
    padding: 2,
  },
  bottomImage: {
    width: '100%',
    height: 'auto',
    maxWidth: '100%',
    display: 'block',
  },
};

const RoadDetailsColumn = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Stack gap={2} sx={styles.root}>
      <Box sx={styles.contentBox}>
        <Typography variant="h3" fontWeight="bold" color="#000">
          {t('customer:lifeplan.maintenanceDetails.title')}
        </Typography>

        <Typography variant="subtitle1" fontSize="16px">
          {t('customer:lifeplan.maintenanceDetails.description')}
        </Typography>

        <Box sx={styles.formulaWrapper}>
          <Box sx={styles.formulaHeader}>
            <Typography
              variant="subtitle2"
              fontWeight="bold"
              color="common.white"
              textAlign="center"
            >
              {t('customer:lifeplan.maintenanceDetails.formulaTitle')}
            </Typography>
          </Box>
          <Box sx={styles.formulaImage}>
            <Image image="Formula" sx={{ height: '100%', width: 'auto' }} />
          </Box>
        </Box>

        <Typography variant="body2" fontSize="16px">
          <strong>
            {t('customer:lifeplan.maintenanceDetails.exampleLabel')}
          </strong>{' '}
          {t('customer:lifeplan.maintenanceDetails.exampleText')}
        </Typography>
      </Box>

      <Box sx={styles.bottomBox}>
        <Image image="RoadSectionItems" sx={styles.bottomImage} />
      </Box>
    </Stack>
  );
};

export default RoadDetailsColumn;
