import { useTranslation } from 'react-i18next';
import { FC } from 'react';

import TextField from '@/components/formControl/TextField';
import useTranslatedEnum from '@/hooks/useTranslatedEnum';
import RadioSelectField from '@/components/formControl/RadioSelectField';
import { CustomerFormProps, YesNo } from '@/types';
import { getFormKey } from '@/utils/system';

import FormCard from '@/components/ui/FormCard';
import { Stack } from '@mui/material';

const FurtherDataForm: FC<CustomerFormProps> = ({ formKey }) => {
  const { t } = useTranslation();

  const [yseNo] = useTranslatedEnum('YesNo', YesNo);

  const radioValueMapper = (
    value: string | number | boolean,
  ): string | undefined => {
    if (typeof value !== 'boolean') {
      return;
    }

    return value ? YesNo.Yes : YesNo.No;
  };

  const radioValueSelectMapper = (value: string) => {
    return value === YesNo.Yes;
  };

  return (
    <FormCard>
      <Stack gap={14} direction="row" alignItems="flex-start">
        <Stack flex={1} gap={5}>
          <TextField
            inline
            hideErrorMessage
            fieldName={getFormKey(formKey, 'weight')}
            label={t('customer:newCustomer.form.weight.label')}
          />
          <TextField
            inline
            hideErrorMessage
            fieldName={getFormKey(formKey, 'height')}
            label={t('customer:newCustomer.form.height.label')}
          />
          <RadioSelectField
            options={yseNo}
            fieldName={getFormKey(formKey, 'is_smoker')}
            valueTransformer={radioValueMapper}
            selectTransformer={radioValueSelectMapper}
            label={t('customer:newCustomer.form.isSmoker.label')}
          />
        </Stack>

        <Stack flex={1} gap={5}>
          <TextField
            inline
            hideErrorMessage
            fieldName={getFormKey(formKey, 'highest_education')}
            label={t('customer:newCustomer.form.education.label')}
          />
          <TextField
            inline
            hideErrorMessage
            fieldName={getFormKey(formKey, 'rent_amount')}
            label={t('customer:newCustomer.form.rentAmount.label')}
          />
        </Stack>
      </Stack>
    </FormCard>
  );
};

export default FurtherDataForm;
