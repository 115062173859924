import { HeaderCell, RowCell } from '@/components/ui/TableGenerator';

export const CashGrantsStartingThisTear: HeaderCell[][] = [
  [
    { value: 'Fördergesetz' },
    { value: 'Verwendungszweck' },
    { value: 'Aktueller Zahlbeitrag' },
    { value: 'Info' },
  ],
];

export const DownstreamCashFunding: HeaderCell[][] = [
  [
    {
      value: 'Nachgelagerte Bar-Förderung',
      colspan: 4,
    },
  ],
];

export const TotalCashGrants: HeaderCell[][] = [
  [
    { value: 'Bar-Förderung p.a. kumuliert' },
    { value: 'Nachgelagerte Bar-Förderung p.a. kumuliert' },
    { value: 'Gesamte Bar-Förderung kumuliert' },
  ],
];

export const RealEstatePromotion: HeaderCell[][] = [
  [
    {
      value: 'Immoilienförderung',
      colspan: 3,
    },
  ],
];

export const TaxIncentivesRefunds: HeaderCell[][] = [
  [
    { value: 'Fördergesetz' },
    { value: 'Förderbeschreibung' },
    { value: 'Förderhöhe' },
  ],
  [
    {
      value: 'Steuerliche Förderung & Rückerstattung',
      colspan: 3,
    },
  ],
];

export const CostsTableHeader: HeaderCell[][] = [
  [
    { value: 'Maßnahme' },
    { value: 'Intervall' },
    { value: 'Investitionskosten' },
  ],
];

export const CostsTableRows: RowCell[][] = [
  [
    { value: 'Fenster streichen' },
    { value: '7 Jahre' },
    { value: 'Ca. 2.000€' },
  ],
  [
    { value: 'Holz & Fassade pflegen' },
    { value: '7 Jahre' },
    { value: 'Ca. 2.000€' },
  ],
  [
    { value: 'Neuer Außenputz' },
    { value: '15 Jahre' },
    { value: 'Ca. 15.000€' },
  ],
  [
    { value: 'Fenstertausch' },
    { value: '15 Jahre' },
    { value: 'Ca. 500€ pro Fenster' },
  ],
  [
    { value: 'Außenwanddämmung' },
    { value: '15 Jahre' },
    { value: 'Ca. 25.000€' },
  ],
  [
    { value: 'Neue Einbauküche' },
    { value: '15 Jahre' },
    { value: 'Ca. 12.000€' },
  ],
  [{ value: 'Neues Bad' }, { value: '20 Jahre' }, { value: 'Ca. 14.000€' }],
  [
    { value: 'Austausch Heizungsanlage' },
    { value: '20 Jahre' },
    { value: 'Ca. 15-30.000€' },
  ],
  [
    { value: 'Elektroinstallation' },
    { value: '23 Jahre' },
    { value: 'Ca. 20.000€' },
  ],
  [{ value: 'Dachdämmung' }, { value: '30 Jahre' }, { value: 'Ca. 15.000€' }],
  [
    { value: 'Dacheindeckung' },
    { value: '40 Jahre' },
    { value: 'Ca. 20.000€' },
  ],
];

export const RoadSectionTableHeader: HeaderCell[][] = [
  [
    { value: 'Maßnahme' },
    { value: 'Intervall' },
    { value: 'Investitionskosten' },
  ],
];

export const RoadSectionTableRows: RowCell[][] = [
  [
    { value: 'Fenster streichen' },
    { value: '7 Jahre' },
    { value: 'Ca. 2.000€' },
  ],
  [
    { value: 'Holz & Fassade pflegen' },
    { value: '7 Jahre' },
    { value: 'Ca. 2.000€' },
  ],
  [
    { value: 'Neuer Außenputz' },
    { value: '15 Jahre' },
    { value: 'Ca. 15.000€' },
  ],
  [
    { value: 'Fenstertausch' },
    { value: '15 Jahre' },
    { value: 'Ca. 500€ pro Fenster' },
  ],
  [
    { value: 'Außenwanddämmung' },
    { value: '15 Jahre' },
    { value: 'Ca. 25.000€' },
  ],
  [
    { value: 'Neue Einbauküche' },
    { value: '15 Jahre' },
    { value: 'Ca. 12.000€' },
  ],
  [{ value: 'Neues Bad' }, { value: '20 Jahre' }, { value: 'Ca. 14.000€' }],
  [
    { value: 'Austausch Heizungsanlage' },
    { value: '20 Jahre' },
    { value: 'Ca. 15–30.000€' },
  ],
  [
    { value: 'Elektroinstallation' },
    { value: '23 Jahre' },
    { value: 'Ca. 20.000€' },
  ],
  [{ value: 'Dachdämmung' }, { value: '30 Jahre' }, { value: 'Ca. 15.000€' }],
  [
    { value: 'Dacheindeckung' },
    { value: '40 Jahre' },
    { value: 'Ca. 20.000€' },
  ],
];

export const RoadSectionAnalyticsTableHeader: HeaderCell[][] = [
  [
    {
      value: 'Größere Ausgaben',
    },
    { value: 'Votum' },
    { value: 'Preisbewusst' },
    { value: 'Preis/Leistung' },
    { value: 'Hochwertig' },
  ],
];

export interface ExtendedRow {
  row: RowCell[];
  relatesTo?: string;
}

export const RoadSectionAnalyticsTableRows: ExtendedRow[] = [
  {
    row: [
      {
        value:
          'Neue Möbel: Sofa, Schränke, Bett, Tisch (Bsp. Bett 1.000€, 10 Jahre)',
        color: '',
        bgColor: '',
      },
      { value: 'Nein', color: 'red', bgColor: '' },
      { value: '9.768,00 €', color: '', bgColor: '' },
      { value: '14.965,00 €', color: '', bgColor: '' },
      { value: '23.871,00 €', color: '', bgColor: '' },
    ],
    relatesTo: 'Renovierung & Wohnungseinrichtung',
  },
  {
    row: [
      {
        value:
          'Elektrogeräte: TV, Waschmaschine (Bsp. Waschmaschine 700€, 10 Jahre)',
        color: '',
        bgColor: '',
      },
      { value: 'Nein', color: 'red', bgColor: '' },
      { value: '7.283,00 €', color: '', bgColor: '' },
      { value: '13.416,00 €', color: '', bgColor: '' },
      { value: '21.526,00 €', color: '', bgColor: '' },
    ],
    relatesTo: 'Renovierung & Wohnungseinrichtung',
  },
  {
    row: [
      {
        value:
          'Autoreparatur: Reifen, Zubehör, Reparatur (Bsp. Winterreifen 1.000€, 5 Jahre)',
        color: '',
        bgColor: '',
      },
      { value: 'Nein', color: 'red', bgColor: '' },
      { value: '6.864,00 €', color: '', bgColor: '' },
      { value: '11.976,00 €', color: '', bgColor: '' },
      { value: '13.976,00 €', color: '', bgColor: '' },
    ],
    relatesTo: 'Neues Auto & Reparatur',
  },
  {
    row: [
      {
        value:
          'Eine Renovierung: Farbe, Vorhänge, Balkonmöbel (Bsp. Vorhänge 8×500€)',
        color: '',
        bgColor: '',
      },
      { value: 'Nein', color: 'red', bgColor: '' },
      { value: '3.976,00 €', color: '', bgColor: '' },
      { value: '5.354,00 €', color: '', bgColor: '' },
      { value: '6.172,00 €', color: '', bgColor: '' },
    ],
    relatesTo: 'Renovierung & Wohnungseinrichtung',
  },
  {
    row: [
      {
        value:
          'Neue Küche: Küche und Küchengeräte (Bsp. 8.000€ für eine Küche)',
        color: '',
        bgColor: '',
      },
      { value: 'Nein', color: 'red', bgColor: '' },
      { value: '5.107,00 €', color: '', bgColor: '' },
      { value: '11.831,00 €', color: '', bgColor: '' },
      { value: '26.517,00 €', color: '', bgColor: '' },
    ],
    relatesTo: 'Renovierung & Wohnungseinrichtung',
  },
  {
    row: [
      { value: 'Neues Auto (Bsp. 25.000€, 10 Jahre)', color: '', bgColor: '' },
      { value: 'Nein', color: 'red', bgColor: '' },
      { value: '17.491,00 €', color: '', bgColor: '' },
      { value: '27.912,00 €', color: '', bgColor: '' },
      { value: '54.617,00 €', color: '', bgColor: '' },
    ],
    relatesTo: 'Neues Auto & Reparatur',
  },
  {
    row: [
      {
        value: 'Umzug: Kaution, Möbel, Transport (Bsp. Kaution 3.000€)',
        color: '',
        bgColor: '',
      },
      { value: 'Nein', color: 'red', bgColor: '' },
      { value: '5.295,00 €', color: '', bgColor: '' },
      { value: '7.964,00 €', color: '', bgColor: '' },
      { value: '10.007,00 €', color: '', bgColor: '' },
    ],
    relatesTo: 'Umzug & Kaution',
  },
  {
    row: [
      {
        value:
          'Große Renovierung: Boden, Bad, Reparaturen (Bsp. Bad 7.000€, 20 Jahre)',
        color: '',
        bgColor: '',
      },
      { value: 'Nein', color: 'red', bgColor: '' },
      { value: '11.563,00 €', color: '', bgColor: '' },
      { value: '17.016,00 €', color: '', bgColor: '' },
      { value: '23.716,00 €', color: '', bgColor: '' },
    ],
    relatesTo: 'Renovierung & Wohnungseinrichtung',
  },
  {
    row: [
      {
        value:
          'Regelmäßige Urlaube (Bsp. Jedes Jahr 2.000€ = 40.000€ in 20 Jahren)',
        color: '',
        bgColor: '',
      },
      { value: 'Nein', color: 'red', bgColor: '' },
      { value: '23.457,00 €', color: '', bgColor: '' },
      { value: '39.997,00 €', color: '', bgColor: '' },
      { value: '52.917,00 €', color: '', bgColor: '' },
    ],
    relatesTo: 'Regelmäßige Urlaube',
  },
  {
    row: [
      {
        value:
          'Neue Immobilie: EK, Notar, Steuer, Makler (Bsp. Kaufnebenkosten ca. 12%)',
        color: '',
        bgColor: '',
      },
      { value: 'Nein', color: 'red', bgColor: '' },
      { value: '24.884,00 €', color: '', bgColor: '' },
      { value: '41.405,00 €', color: '', bgColor: '' },
      { value: '85.821,00 €', color: '', bgColor: '' },
    ],
    relatesTo: 'Neue Immobilie erwerben',
  },
  {
    row: [
      {
        value: 'Existenzsicherung im Alter (Kapitaltopf für größere Ausgaben)',
        color: '',
        bgColor: '',
      },
      { value: 'Nein', color: 'red', bgColor: '' },
      { value: '100.000,00 €', color: '', bgColor: '' },
      { value: '200.000,00 €', color: '', bgColor: '' },
      { value: '300.000,00 €', color: '', bgColor: '' },
    ],
    relatesTo: 'Kapital im Rentenalter',
  },
  {
    row: [
      { value: 'Individuelle Ziele', color: '', bgColor: '' },
      { value: 'Nein', color: 'red', bgColor: '' },
      { value: '0,00 €', color: '', bgColor: '', colspan: 3 },
    ],
    relatesTo: 'Individuelle Ziele',
  },
];

  export const SavingsTableHeader: HeaderCell[][] = [
    [
      { value: 'Kalenderjahr' },
      { value: 'Lebensalter' },
      { value: 'Beitrag' },
      { value: 'Bar-Förderung' },
      { value: 'Eingezahlt' },
      { value: 'Einzahlung / Entnahme' },
      { value: 'Kapital' },
      { value: 'Förderung (Steuerfrei)' },
    ],
  ];

