import { useTranslation } from 'react-i18next';
import { FC } from 'react';

import TextField from '@/components/formControl/TextField';
import DateField from '@/components/formControl/DateField';
import SelectField from '@/components/formControl/SelectField';
import useTranslatedEnum from '@/hooks/useTranslatedEnum';

import { CustomerFormProps, MaritalStatus, YesNo } from '@/types';
import { getFormKey } from '@/utils/system';
import moment from 'moment';
import FormCard from '@/components/ui/FormCard';
import { Stack, Typography } from '@mui/material';

const PersonalDetailsForm: FC<CustomerFormProps> = ({ formKey }) => {
  const { t } = useTranslation();

  const [maritalStatus] = useTranslatedEnum('MaritalStatus', MaritalStatus);

  return (
    <FormCard>
      <Typography variant="overline" pb={4}>
        {t('customer:newCustomer.mainInfo')}
      </Typography>
      <Stack direction="row" alignItems="flex-start" gap={14}>
        <Stack flex={1} gap={6}>
          <TextField
            inline
            hideErrorMessage
            fieldName={getFormKey(formKey, 'first_name')}
            label={t('customer:newCustomer.form.firstName.label')}
          />
          <TextField
            inline
            hideErrorMessage
            fieldName={getFormKey(formKey, 'last_name')}
            label={t('customer:newCustomer.form.lastName.label')}
          />
          <DateField
            fieldName={getFormKey(formKey, 'date_of_birth')}
            label={t('customer:newCustomer.form.dateOfBirth.label')}
            saveFormat="YYYY-MM-DD"
            max={moment()}
          />
          <SelectField
            fieldName={getFormKey(formKey, 'marital_status')}
            items={maritalStatus}
            label={t('customer:newCustomer.form.maritalStatus.label')}
          />
        </Stack>
        <Stack flex={1} gap={6}>
          <TextField
            inline
            hideErrorMessage
            fieldName={getFormKey(formKey, 'postal_code')}
            label={t('customer:newCustomer.form.postalCode.label')}
          />
          <TextField
            inline
            hideErrorMessage
            fieldName={getFormKey(formKey, 'city')}
            label={t('customer:newCustomer.form.city.label')}
          />
          <TextField
            inline
            hideErrorMessage
            fieldName={getFormKey(formKey, 'address')}
            label={t('customer:newCustomer.form.address.label')}
          />
        </Stack>
      </Stack>
    </FormCard>
  );
};

export default PersonalDetailsForm;
