import { Box, Typography, useTheme } from '@mui/material';
import Section from '@/components/ui/Section';
import { useTranslation } from 'react-i18next';
import Image from '@/components/ui/Image';

const styles = {
  image: {
    width: 120,
    height: 'auto',
    cursor: 'pointer',
  },
  container: {
    padding: 2,
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 2,
  },
  textContainer: {
    marginTop: 0.5,
    fontStyle: 'italic',
  },
};

const RentFree = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Section>
      <Box sx={styles.container}>
        <Box sx={styles.flexContainer}>
          <Box>
            <Typography variant="subtitle2" color={theme.palette.text.primary}>
              {t('customer:lifeplan.rentFreeNotCostFree')}
            </Typography>

            <Typography variant="h6" fontWeight="bold" sx={{ mt: 0.5 }}>
              {t('customer:lifeplan.socialHorrorScenario')}
            </Typography>

            <Typography
              variant="body2"
              color={theme.palette.text.primary}
              sx={styles.textContainer}
            >
              {t('customer:lifeplan.lastUpdated')}
            </Typography>
          </Box>
          <Box
            component="a"
            href="https://www.google.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image image="Focus" sx={styles.image} />
          </Box>
        </Box>
        <Typography variant="body2" color={theme.palette.text.secondary}>
          {t('customer:lifeplan.rentFreeDescription')}
        </Typography>
      </Box>
    </Section>
  );
};

export default RentFree;
