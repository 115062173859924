import { create } from 'zustand';
import { CustomerState, CustomerStore } from '@/store/customer/types';
import { ErrorResponse } from '@/utils';
import api from '@/services/api';
import { Insurance } from '@/types';
import { persist } from 'zustand/middleware';

const initialState: CustomerState = {
  customerId: null,
  customer: null,
  partner: null,
  loading: false,
  error: null,
  insurances: null,
  funding: null,
  savings: null,
};

const useCustomerStore = create(
  persist<CustomerStore>(
    (set, state) => ({
      ...initialState,
      createCustomer: async (customerKey, data) => {
        set({ loading: true });
        try {
          const customer = await api.customer.createCustomer(data);
          if (data?.partner_id && customerKey === 'partner') {
            state().fetchCustomer('customer', data.partner_id);
          }
          set({ [customerKey]: customer });
          if (customerKey === 'customer') {
            set({ customerId: customer.id });
          }
        } catch (e) {
          set({ error: (e as ErrorResponse).getType() });
        } finally {
          set({ loading: false });
        }
      },
      fetchCustomer: async (customerKey, id) => {
        set({ loading: true });
        try {
          const customer = await api.customer.getCustomer(id.toString());
          set({ [customerKey]: customer });
          if (customerKey === 'customer') {
            set({ customerId: customer.id });
            state().getInsurances();
          }
        } catch (e) {
          set({ error: (e as ErrorResponse).getType() });
        } finally {
          set({ loading: false });
        }
      },
      updateCustomer: async (customerKey, id, data) => {
        set({ loading: true });
        try {
          const customer = await api.customer.updateCustomer(
            id.toString(),
            data,
          );
          set({ [customerKey]: customer });
        } catch (e) {
          set({ error: (e as ErrorResponse).getType() });
        } finally {
          set({ loading: false });
        }
      },
      clearState: () => {
        set(initialState);
      },
      getInsurances: async () => {
        const customerId = state().customer?.id;

        if (!customerId) return;

        set({ loading: true });
        try {
          const insurances = await api.customer.getInsurances(
            customerId.toString(),
          );
          set({ insurances });
        } catch (e) {
          set({ error: (e as ErrorResponse).getType() });
        } finally {
          set({ loading: false });
        }
      },
      addInsurance: async (data: Partial<Insurance>) => {
        const customerId = state().customer?.id;

        if (!customerId) return;

        set({ loading: true });
        try {
          await api.customer.addInsurance(customerId.toString(), data);
          await state().getInsurances();
        } catch (e) {
          set({ error: (e as ErrorResponse).getType() });
        } finally {
          set({ loading: false });
        }
      },
      addEmptyLifeInsurance: async () => {
        const insurances = state().insurances;
        console.log(insurances);
        set({
          insurances: [
            ...(insurances || []),
            { category: 'life_financial' } as Insurance,
          ],
        });
      },
      deleteEmptyLifeInsurance: () => {
        const insurances = state().insurances || [];
        const filtered = insurances.filter(
          (insurance) =>
            insurance.category !== 'life_financial' ||
            insurance.id !== undefined,
        );

        set({ insurances: filtered });
      },
      updateInsurance: async (id, data: Partial<Insurance>) => {
        const customerId = state().customer?.id;

        if (!customerId) return;

        set({ loading: true });
        try {
          await api.customer.updateInsurance(customerId.toString(), id, data);
          await state().getInsurances();
        } catch (e) {
          set({ error: (e as ErrorResponse).getType() });
        } finally {
          set({ loading: false });
        }
      },
      deleteInsurance: async (id) => {
        const customerId = state().customer?.id;

        if (!customerId) return;

        set({ loading: true });
        try {
          await api.customer.deleteInsurance(customerId.toString(), id);
          await state().getInsurances();
        } catch (e) {
          set({ error: (e as ErrorResponse).getType() });
        } finally {
          set({ loading: false });
        }
      },
      getFunding: async () => {
        const customerId = state().customerId;
        if (!customerId) return;

        set({ loading: true });
        try {
          const funding = await api.customer.getFundingsByCustomerId(
            customerId.toString(),
          );
          set({ funding });
        } catch (e) {
          set({ error: (e as ErrorResponse).getType() });
        } finally {
          set({ loading: false });
        }
      },
      calculateSaving: async (data, type: 'diagram' | 'children') => {
        const customerId = state().customerId;
        if (!customerId) return;

        set({ loading: true });

        try {
          const savings = await api.customer.calculateSaving(
            customerId,
            data,
            type,
          );
          set({ savings });
        } catch (e) {
          set({ error: (e as ErrorResponse).getType() });
        } finally {
          set({ loading: false });
        }
      },
    }),
    {
      name: 'customer-storage',
      partialize: (state) => {
        return { customerId: state.customerId } as CustomerStore;
      },
    },
  ),
);

export default useCustomerStore;
