import SideBar from '@/components/ui/SideBar';
import { CustomerDetailsSideBar } from '@/constants/sidebar';
import { Stack } from '@mui/material';
import { Routes } from '@/constants/routes';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { getPath } from '@/utils';
import { useTranslation } from 'react-i18next';
import useCustomerStore from '@/store/customer';
import { SideBarItemData } from '@/components/ui/SideBarItem';
import { useEffect } from 'react';

const CustomerView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const customerIdFromSearch = searchParams.get('id');

  const { customerId, customer, fetchCustomer, clearState } =
    useCustomerStore();

  useEffect(() => {
    if (customerId && !customer) {
      fetchCustomer('customer', customerId).catch(console.error);
    }
  }, [customer, customerId, customerIdFromSearch, fetchCustomer]);

  useEffect(() => {
    if (customerIdFromSearch && !customerId) {
      fetchCustomer('customer', customerIdFromSearch).catch(console.error);
    }
  }, [customerId, customerIdFromSearch, fetchCustomer]);

  const handleExitFromCustomer = () => {
    clearState();
    navigate(getPath('..', Routes.Home, Routes.Customers));
  };

  const handleSideBarItemClick = (item: SideBarItemData) => {
    if (item.path === Routes.Root) {
      return;
    }
    navigate(getPath('.', item.path));
  };

  return (
    <Stack
      flex={1}
      direction="row"
      overflow="hidden"
      bgcolor="background.screen"
    >
      <SideBar
        items={CustomerDetailsSideBar}
        backLabel={t('customer:newCustomer.goBack')}
        onGoBack={handleExitFromCustomer}
        onSideBarItemClick={handleSideBarItemClick}
      />
      <Outlet />
    </Stack>
  );
};

export default CustomerView;



