import { useTranslation } from 'react-i18next';
import { FC } from 'react';

import TextField from '@/components/formControl/TextField';
import { CustomerFormProps } from '@/types';
import { getFormKey } from '@/utils/system';

import FormCard from '@/components/ui/FormCard';
import { Stack } from '@mui/material';

const PropertyDetailsForm: FC<CustomerFormProps> = ({ formKey }) => {
  const { t } = useTranslation();

  return (
    <FormCard>
      <Stack gap={14} direction="row" alignItems="flex-start">
        <Stack flex={0.5} gap={5}>
          <TextField
            inline
            hideErrorMessage
            fieldName={getFormKey(formKey, 'living_space')}
            label={t('customer:newCustomer.form.livingSpace.label')}
          />
        </Stack>
      </Stack>
    </FormCard>
  );
};

export default PropertyDetailsForm;
