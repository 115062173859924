import { Stack, TableCell, TableRow } from '@mui/material';
import CellSelectField from '@/components/formControl/CellSelectField';
import { ProductList } from '@/constants/insurances';
import CellTextField from '@/components/formControl/CellTextField';

import { FC, useEffect, useMemo, useState } from 'react';
import { getFormKey } from '@/utils/system';
import { ProductsTable } from '@/types';
import useFormField from '@/hooks/useFormField';
import useCustomerStore from '@/store/customer';

export interface ProductRowProps {
  formKey: string;
  index: number;
  onDelete: (i: number) => void;
  onUpdate?: (product: Partial<ProductsTable>) => void;
}

const ProductRow: FC<ProductRowProps> = ({ onDelete, formKey, onUpdate }) => {
  const { value, setValue } = useFormField<Partial<ProductsTable>>(formKey, {});
  const [selectedProduct, setSelectedProduct] = useState('');
  const { funding, getFunding } = useCustomerStore();

  useEffect(() => {
    if (!funding) {
      getFunding().catch(console.error);
    }
  }, [funding, getFunding]);

  useEffect(() => {
    if (value.product) {
      setSelectedProduct(value.product);
    }
  }, [value.product]);

  const calculatedFunding = useMemo(() => {
    if (!funding) return '';
    if (selectedProduct === 'kirchensteuer') {
      return funding[2]?.sum ? (funding[2].sum / 12).toFixed(2) : '';
    }
    if (selectedProduct === 'foerderpolice') {
      return funding[0]?.sum ? funding[0].sum.toFixed(2) : '';
    }
    return '';
  }, [selectedProduct, funding]);

  useEffect(() => {
    if (calculatedFunding !== '') {
      setValue({ ...value, funding_immediate: Number(calculatedFunding) });
    }
  }, [calculatedFunding]);

  const handleUpdateSelect = (val: string) => {
    setSelectedProduct(val);
    onUpdate?.({ product: val });
  };

  const handleUpdate = () => {
    onUpdate?.(value);
  };

  const handleDelete = () => {
    if (!value.id) return;
    onDelete(value.id);
  };

  const formattedDownstream = useMemo(() => {
    switch (selectedProduct) {
      case 'etf_police':
      case 'verm_bg_police':
      case 'etf_police_instandhaltung':
        return '63.916,52 € Steuerfrei';
      case 'etf_kinderpolice':
      case 'etf_kinderpolice_ask':
        return '12.640,00 € Steuerfrei';
      case 'child_rearing_periods':
        return '0,00 € p.a.';
      default:
        return '';
    }
  }, [selectedProduct]);

  return (
    <TableRow>
      <CellSelectField
        fieldName={getFormKey(formKey, 'product')}
        items={ProductList}
        onSelect={handleUpdateSelect}
      />
      <CellTextField
        fieldName={getFormKey(formKey, 'provider')}
        onBlur={handleUpdate}
      />
      <CellTextField
        tableCellProps={{ width: 110 }}
        fieldName={getFormKey(formKey, 'contribution')}
        endAdornment="€"
        valueEndAdornment="€"
        onBlur={handleUpdate}
      />
      <CellTextField
        tableCellProps={{ width: 110 }}
        fieldName={getFormKey(formKey, 'funding_immediate')}
        valueEndAdornment="€"
        endAdornment="€"
        disabled
        addPadding={true}
      />
      <TableCell>
        <Stack sx={{ padding: '24px' }}>{formattedDownstream}</Stack>
      </TableCell>
    </TableRow>
  );
};

export default ProductRow;
