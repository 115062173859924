import { FC } from 'react';
import { Stack, TextField, Typography } from '@mui/material';
import useFormField from '@/hooks/useFormField';
import style from './style';

interface NumberFieldProps {
  fieldName: string;
  label?: string;
  subtitle?: string;
  labelFixedWidth?: string;
  inTable?: boolean;
}

const NumberField: FC<NumberFieldProps> = ({
  fieldName,
  label,
  subtitle,
  labelFixedWidth,
  inTable,
}) => {
  const { value, error, setValue } = useFormField<string>(fieldName, '');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <Stack sx={style.container}>
      {!!label && (
        <Stack width={labelFixedWidth || '254px'}>
          <Typography
            variant="body1"
            sx={style.label}
            color={error ? 'error.main' : 'text.secondary'}
          >
            {label}
          </Typography>
          {!!subtitle && (
            <Typography
              variant="body2"
              color={error ? 'error.main' : 'text.primary'}
            >
              {subtitle}
            </Typography>
          )}
        </Stack>
      )}
      <Stack flex={1} overflow="hidden">
        <TextField
          type="number"
          value={value}
          onChange={handleChange}
          error={!!error}
          helperText={error || ''}
          sx={[style.input]}
          slotProps={{
            input: {
              sx: {
                height: '58px',
              },
            },
          }}
        />
      </Stack>
    </Stack>
  );
};

export default NumberField;
