import { Stack } from '@mui/system';
import { FC, useEffect, useRef, useState } from 'react';
import useCustomerStore from '@/store/customer';
import Roadmap from './Roadmap';
import RentFree from './LifeRoadSections/RentFree';
import RoadSection from './LifeRoadSections/RoadSection';
import KinderSection from './LifeRoadSections/KinderSection';
import InterestRateSection from './LifeRoadSections/InterestRateSection';
import FocusEmpty from './LifeRoadSections/FocusEmpty';
import InvestmentSection from './LifeRoadSections/InvestmentSection';
import AnalyticsSection from './LifeRoadSections/AnalyticsSection';
import LifeRoadTables from './LifeRoadTables';
import PositiveCurve from './LifeRoadSections/PositiveCurve';
import CostsTable from './LifeRoadSections/CostsTable';
import NegativeCurve from './LifeRoadSections/NegativeCurve';

export interface CardData {
  name: string;
  isActive: boolean;
  isPositive: boolean;
}

const LifeRoadPage: FC = () => {
  const { customer, customerId, updateCustomer } = useCustomerStore();
  const [cards, setCards] = useState<Record<string, CardData>>({});

  useEffect(() => {
    if (customer?.settings?.cards) {
      setCards(customer.settings.cards);
    }
  }, [customer]);

  const handleCardsChange = (cardsArray: CardData[]) => {
    const updatedCards = cardsArray.reduce<Record<string, CardData>>(
      (acc, card, index) => {
        acc[`card${index}`] = card;
        return acc;
      },
      {},
    );

    if (JSON.stringify(updatedCards) === JSON.stringify(cards)) return;

    setCards(updatedCards);

    if (customerId && customer) {
      updateCustomer('customer', customerId, {
        settings: {
          options: customer.settings?.options || {},
          cards: updatedCards,
        },
      });
    }
  };

  return (
    <>
      {customer && (
        <Stack px={6} pb={6} gap={6} overflow="auto">
          <Roadmap getCardsData={handleCardsChange} initialCards={cards} />
          <LifeRoadTables cards={Object.values(cards)} />
          {Object.entries(cards).map(
            ([key, card]) =>
              card?.isPositive && (
                <div key={key}>
                  {key === 'card0' && <RoadSection />}
                  {key === 'card1' && <KinderSection />}
                  {key === 'card2' && <InterestRateSection />}
                  {key === 'card3' && (
                    <Stack gap={6}>
                      <RentFree />
                      <PositiveCurve />
                      <CostsTable />
                    </Stack>
                  )}
                  {key === 'card4' && (
                    <Stack gap={6}>
                      <FocusEmpty />
                      <NegativeCurve />
                    </Stack>
                  )}
                  {key === 'card5' && (
                    <Stack gap={6}>
                      <InvestmentSection />
                      <AnalyticsSection />
                    </Stack>
                  )}
                </div>
              ),
          )}
        </Stack>
      )}
    </>
  );
};

export default LifeRoadPage;
