import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Section from '@/components/ui/Section';
import { BarChart } from '@mui/x-charts';
import { SXStyleSheet } from '@/utils';
import useCustomerStore from '@/store/customer';
import { useMemo } from 'react';
import { filter, map, reduce, sortBy } from 'lodash';
import { Insurance } from '@/types';
import SavingsCompare from '@/components/tables/SavingsCompare';
import { euro, futureValue } from '@/utils/math';
import Decimal from 'decimal.js';
import CTAButton from '@/components/ui/CTAButton';

const xLabels = ['Kredit', 'ETF-Police'];

const SavingsView = () => {
  const { t } = useTranslation();

  const {
    insurances,
    addInsurance,
    updateInsurance,
    addEmptyLifeInsurance,
    deleteEmptyLifeInsurance,
  } = useCustomerStore();

  const lifeFinancial = useMemo(() => {
    return filter(insurances, { category: 'life_financial' });
  }, [insurances]);

  const handleUpdate = ({ id, ...insurance }: Partial<Insurance>) => {
    if (insurance.type && !id) {
      addInsurance({
        ...insurance,
        category: 'life_financial',
      });
    } else if (id) {
      updateInsurance(id, insurance);
    }
  };

  const barValueSum = useMemo(() => {
    const plInsurances = filter(insurances, { category: 'life_financial' });

    const sumMonthlyPayment = reduce(
      plInsurances,
      (acc, item) => {
        return {
          sum: acc.sum.add(new Decimal(item.monthly_payment || 0).toNumber()),
          newSum: acc.newSum.add(
            new Decimal(item.new_monthly_payment || 0).toNumber(),
          ),
          fv: acc.fv.add(
            futureValue(
              (item.roi || 0) / 100 / 12,
              12 * (item.duration_in_years || 0),
              -(new Decimal(item.monthly_payment || 0).toNumber() || 0),
              -(item.credit_balance || 0),
            ).toNumber(),
          ),
          newFv: acc.newFv.add(
            futureValue(
              (item.roi || 0) / 100 / 12,
              12 * (item.duration_in_years || 0),
              -(new Decimal(item.new_monthly_payment || 0).toNumber() || 0),
              -(item.credit_balance || 0),
            ).toNumber(),
          ),
        };
      },
      {
        sum: euro(0),
        fv: euro(0),
        newSum: euro(0),
        newFv: euro(0),
      },
    );

    const maxMonthlyPayment = sortBy(
      plInsurances,
      'duration_in_years',
    ).reverse()?.[0]?.duration_in_years;

    return {
      sumAnnual: sumMonthlyPayment.sum.multiply(12).multiply(maxMonthlyPayment),
      futureValue: sumMonthlyPayment.fv,
      newSumAnnual: sumMonthlyPayment.newSum
        .multiply(12)
        .multiply(maxMonthlyPayment),
      newFutureValue: sumMonthlyPayment.newFv,
    };
  }, [insurances]);

  const totalFutureValueBenefit = barValueSum.futureValue.subtract(
    barValueSum.sumAnnual,
  );
  const totalNewFutureValueBenefit = barValueSum.newFutureValue.subtract(
    barValueSum.newSumAnnual,
  );

  const hasEmptyLifeInsurance = useMemo(() => {
    return insurances?.some(
      (insurance) =>
        insurance.category === 'life_financial' && insurance.id === undefined,
    );
  }, [insurances]);

  return (
    <Stack flex={1} px={6} pb={6} gap={6} overflow="auto">
      {map(lifeFinancial, (insurance) => (
        <Section>
          <SavingsCompare
            insurance={insurance}
            onUpdate={handleUpdate}
            key={insurance.id}
          />
        </Section>
      ))}
      <Stack direction="row" mb={8} spacing={2}>
        <CTAButton
          fixedWidth={258}
          onClick={addEmptyLifeInsurance}
          variant="secondary"
          icon="Plus"
          label={t('customer:lifeInsurance.action.add')}
        />
        <CTAButton
          fixedWidth={258}
          onClick={deleteEmptyLifeInsurance}
          variant="tertiary"
          icon="DeleteGray"
          label={t('customer:lifeInsurance.action.delete')}
          disabled={!hasEmptyLifeInsurance}
        />
      </Stack>
      <Section>
        <Stack gap="19px">
          <Typography variant="subtitle2">
            {t('customer:bsc.section3.title')}
          </Typography>
          <Stack sx={style.barChartContainer}>
            <BarChart
              height={536}
              margin={{ bottom: 102 }}
              sx={style.chart}
              grid={{ horizontal: true }}
              borderRadius={8}
              barLabel={(item) => {
                if (!item.value) return;
                return `${item.value?.toString()} €`;
              }}
              slotProps={{
                legend: {
                  position: { vertical: 'bottom', horizontal: 'middle' },
                  padding: { top: 100 },
                  itemGap: 48,
                },
              }}
              series={[
                {
                  data: [
                    barValueSum.sumAnnual.value,
                    barValueSum.newSumAnnual.value,
                  ],
                  label: 'Verlust',
                  color: '#036A8B',
                  stack: 'A',
                },
                {
                  data: [
                    totalFutureValueBenefit.value,
                    totalNewFutureValueBenefit.value,
                  ],
                  label: 'Rendite',
                  color: '#00ABEA',
                  stack: 'A',
                },
                {
                  data: [
                    totalNewFutureValueBenefit.subtract(totalFutureValueBenefit)
                      .value,
                    0,
                  ],
                  label: 'Einzahlungen',
                  color: '#85D7F5',
                  stack: 'A',
                },
              ]}
              xAxis={[
                {
                  scaleType: 'band',
                  data: xLabels,
                  disableLine: true,
                  tickLabelStyle: {
                    display: 'none',
                  },
                },
              ]}
              yAxis={[
                {
                  disableLine: true,
                  disableTicks: true,
                  tickNumber: 4,
                },
              ]}
            />
          </Stack>
        </Stack>
      </Section>
    </Stack>
  );
};

const style = SXStyleSheet.create({
  chart: {
    '& .MuiChartsLegend-mark': {
      rx: '4px',
    },
    '& .MuiChartsAxis-tick': {
      stroke: 'transparent !important',
    },
    '& .MuiBarLabel-root': {
      fontSize: '16px',
      fill: '#FFFFFF',
      fontWeight: 600,
    },
  },
  image: {
    width: '100%',
  },
  barChartContainer: {
    backgroundColor: 'background.paper',
    borderRadius: 2,
    padding: 6,
    paddingBottom: 2,
  },
});

export default SavingsView;
