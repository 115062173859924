import { FC, useEffect, useState } from 'react';
import { Box, Card, Typography, Button, Stack } from '@mui/material';
import Section from '@/components/ui/Section';
import { CardData } from './LifeRoadPage';
import Image from '@/assets/images';

interface RoadmapProps {
  getCardsData: (cardsData: CardData[]) => void;
  initialCards: Record<string, CardData>;
}

const defaultCards: CardData[] = [
  { name: 'Zukunftsplan für Ihre Kinder', isActive: false, isPositive: false },
  {
    name: 'Instandhaltungs-rücklage für Ihre Immobilie',
    isActive: false,
    isPositive: false,
  },
  { name: 'Existenzsicherung im Alter', isActive: false, isPositive: false },
  { name: 'Vermögensaufbau', isActive: false, isPositive: false },
  { name: 'Erwerb einer Immobilie', isActive: false, isPositive: false },
  {
    name: 'Früher Schuldenfrei (Immobilien-darlehen)',
    isActive: false,
    isPositive: false,
  },
];

const styles = {
  container: {
    width: '100%',
    aspectRatio: '16 / 9',
    margin: '0 auto',
    borderRadius: 2,
    overflow: 'hidden',
    backgroundImage: `url(${Image.RoadImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    py: 4,
  },
  rowGap: {
    xs: '20px',
    sm: '5vw',
    md: 'clamp(20px, 15vw, 66px)',
  },
  colGap: {
    xs: '20px',
    sm: '30px',
    md: '50px',
    lg: '100px',
    xl: '140px',
    xxl: '165px',
  },
  buttonBase: {
    width: 20,
    height: 20,
    minWidth: 20,
    borderRadius: '50%',
  },
  cardBase: {
    width: 164,
    height: 105,
    p: 2,
    backdropFilter: 'blur(10px)',
    borderRadius: 2,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'box-shadow 0.3s ease-in-out',
  },
  label: {
    fontSize: '14px',
  },
};

const Roadmap: FC<RoadmapProps> = ({ getCardsData, initialCards }) => {
  const [cards, setCards] = useState<CardData[]>(defaultCards);

  useEffect(() => {
    const values = Object.values(initialCards);
    if (values.length > 0) {
      setCards(values);
    }
  }, [initialCards]);

  const handleUpdate = (updatedCards: CardData[]) => {
    setCards(updatedCards);
    getCardsData(updatedCards);
  };

  const toggleCard = (index: number, isPositive: boolean) => {
    const updated = cards.map((card, i) =>
      i === index ? { ...card, isActive: true, isPositive } : card,
    );
    handleUpdate(updated);
  };

  const resetCard = (index: number) => {
    const updated = cards.map((card, i) =>
      i === index ? { ...card, isActive: false, isPositive: false } : card,
    );
    handleUpdate(updated);
  };

  return (
    <Stack pb={6} gap={6}>
      <Section>
        <Typography variant="h6" fontWeight="bold" mb={2}>
          Straße des Lebens
        </Typography>

        <Box sx={styles.container}>
          <Stack gap={styles.rowGap}>
            {[0, 3].map((rowStart) => (
              <Stack
                key={rowStart}
                direction="row"
                gap={styles.colGap}
                justifyContent="center"
                sx={{
                  pl:
                    rowStart === 3
                      ? {
                          xs: '20px',
                          sm: '5vw',
                          md: 0,
                          lg: 'clamp(20px, 15vw, 100px)',
                          xl: 'clamp(20px, 12vw, 140px)',
                          xxl: 'clamp(20px, 10vw, 165px)',
                        }
                      : 0,
                }}
              >
                {cards.slice(rowStart, rowStart + 3).map((card, index) => {
                  const i = rowStart + index;
                  return (
                    <Stack key={i} spacing={1} alignItems="center">
                      <Stack direction="row" gap={1}>
                        <Button
                          onClick={() => toggleCard(i, false)}
                          sx={{
                            ...styles.buttonBase,
                            backgroundColor:
                              card.isActive && !card.isPositive
                                ? '#D33F33'
                                : 'rgba(255, 255, 255, 0.4)',
                          }}
                        />
                        <Button
                          onClick={() => toggleCard(i, true)}
                          sx={{
                            ...styles.buttonBase,
                            backgroundColor:
                              card.isActive && card.isPositive
                                ? '#56D973'
                                : 'rgba(255, 255, 255, 0.4)',
                          }}
                        />
                      </Stack>
                      <Card
                        onClick={() => resetCard(i)}
                        sx={{
                          ...styles.cardBase,
                          backgroundColor: card.isActive
                            ? 'white'
                            : 'rgba(255, 255, 255, 0.4)',
                          color: card.isActive
                            ? card.isPositive
                              ? '#56D973'
                              : '#D33F33'
                            : 'white',
                          boxShadow: card.isActive
                            ? `0 0 15px ${card.isPositive ? '#56D973' : '#D33F33'}`
                            : '0 4px 12px rgba(0,0,0,0.1)',
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight="semibold"
                          sx={styles.label}
                        >
                          {card.name}
                        </Typography>
                      </Card>
                    </Stack>
                  );
                })}
              </Stack>
            ))}
          </Stack>
        </Box>
      </Section>
    </Stack>
  );
};

export default Roadmap;
