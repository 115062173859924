import { Stack } from '@mui/material';
import CTAButton from '@/components/ui/CTAButton';
import { useTranslation } from 'react-i18next';
import useFormField from '@/hooks/useFormField';
import { Insurance } from '@/types';

const AddInsurance = () => {
  const { t } = useTranslation();
  const { value, setValue } = useFormField<Partial<Insurance>[]>(
    'insurances',
    [],
  );

  const addInsurance = async () => {
    await setValue([...value, {}]);
  };

  return (
    <Stack
      alignItems="flex-start"
      justifyContent="flex-start"
      mt={4}
      mb={2}
      spacing={2}
    >
      <CTAButton
        fixedWidth={258}
        onClick={addInsurance}
        variant="secondary"
        icon="Plus"
        label={t('customer:lifeInsurance.action.add')}
      />
    </Stack>
  );
};

export default AddInsurance;
