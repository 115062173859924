import { SXStyleSheet } from '@/utils';
import { typographyClasses } from '@mui/material';

const style = SXStyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
  },
  label: {
    fontWeight: 500,
    marginBottom:'20px'
  },
  radioGroup: {
    flexDirection: 'row',
    gap: 8,
  },
  select: {
    border: '1px solid',
    borderColor: 'divider',
    flex: 1,
    height: '58px',
    borderRadius: 2,
    alignItems: 'flex-start',
    margin: 0,
    [`.${typographyClasses.root}`]: {
      pt: 2,
    },
  },
});

export default style;
