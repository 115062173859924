import { Stack, Typography } from '@mui/material';
import Section from '@/components/ui/Section';
import { useTranslation } from 'react-i18next';
import TableKeyValue from '@/components/ui/TableKeyValue';
import { euro, getRange } from '@/utils/math';
import InlineTable from '@/components/ui/InlineTable';
import CellTextInput from '@/components/ui/CellTextInput';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import useCustomerStore from '@/store/customer';
import moment from 'moment';
import { BarChart } from '@mui/x-charts';
import { CalculateSavingsRequest } from '@/types';
import { reduce } from 'lodash';
import CellSelectInput from '@/components/ui/CellTextInput/CellSelectInput';
import { Tariffs, TariffsRange } from '@/constants/data';
import TableGenerator, { RowCell } from '@/components/ui/TableGenerator';
import { SavingsTableHeader } from '@/constants/tables';

export type CalculateSavingsState = Omit<
  CalculateSavingsRequest,
  'current_age'
> & {
  tariff: string;
  date_of_birth: string;
};

const defaultsCalculations: CalculateSavingsState = {
  date_of_birth: '',
  savings_rate: 250,
  target_age: 67,
  roi: 6,
  fundings_sum: 480,
  tariff: 'axa',
};

type Action = {
  type: 'update_value';
  payload: {
    key: keyof CalculateSavingsState;
    value: string;
  };
};

export const calculatorReducer = (
  state: CalculateSavingsState,
  action: Action,
) => {
  if (action.type === 'update_value') {
    return {
      ...state,
      [action.payload.key]: action.payload.value,
    };
  }
  throw Error('Unknown action.');
};

const DiagramView = () => {
  const { t } = useTranslation();

  const { customer, savings, calculateSaving } = useCustomerStore();

  const [state, dispatch] = useReducer(calculatorReducer, defaultsCalculations);

  useEffect(() => {
    const { date_of_birth, tariff, ...requestData } = state;

    if (date_of_birth) {
      calculateSaving(
        {
          ...requestData,
          current_age: moment().diff(date_of_birth, 'years'),
        },
        'diagram',
      );
    }
  }, [calculateSaving, state]);

  const savingsData = useMemo(() => {
    return reduce(
      savings,
      (acc, item) => {
        return [
          [...acc[0], item.year],
          [...acc[1], item.capital],
          [...acc[2], item.payment_total],
          [...acc[3], item.subsidy_tax_free],
          [...acc[4], item.withdrawal],
          [...acc[5], item.return_of_investment],
        ];
      },
      [[], [], [], [], [], []] as number[][],
    );
  }, [savings]);

  const SavingsTableRows: RowCell[][] = (savings ?? []).map((item) => [
    { value: `${item.year}` },
    { value: `${item.age}` },
    { value: `${item.annual_payment.toFixed(2)} €` },
    { value: `${item.cash_subsidy.toFixed(2)} €` },
    { value: `${item.payment_total.toFixed(2)} €` },
    { value: `${item.withdrawal.toFixed(2)} €` },
    {
      value: `${item.capital.toLocaleString('de-DE', { minimumFractionDigits: 2 })} €`,
    },
    {
      value: `${item.subsidy_tax_free.toLocaleString('de-DE', { minimumFractionDigits: 2 })} €`,
    },
  ]);

  const selectedRoiRange = useMemo(() => {
    const selectedTariffRange =
      TariffsRange[state.tariff as keyof typeof TariffsRange];
    return getRange(
      selectedTariffRange.start,
      selectedTariffRange.end,
      selectedTariffRange.step,
    );
  }, [state.tariff]);

  const getTotal = useCallback((data: number[]) => {
    return data.reduce((acc, item) => acc.add(item), euro(0));
  }, []);

  useEffect(() => {
    dispatch({
      type: 'update_value',
      payload: {
        key: 'roi',
        value: selectedRoiRange[0].value,
      },
    });
  }, [selectedRoiRange]);

  useEffect(() => {
    dispatch({
      type: 'update_value',
      payload: {
        key: 'date_of_birth',
        value: moment(customer?.date_of_birth).format('YYYY') || '',
      },
    });
  }, [customer?.date_of_birth]);

  return (
    <Stack px={6} pb={6} gap={6} overflow="auto">
      <Section>
        <Stack gap="19px">
          <Typography variant="subtitle2">
            {t('customer:diagram.section1.title')}
          </Typography>
          <InlineTable
            data={state}
            variant="accent"
            mapKeysToTitle={{
              date_of_birth: t('customer:diagram.section1.table.date_of_birth'),
              savings_rate: t('customer:diagram.section1.table.savings_rate'),
              target_age: t('customer:diagram.section1.table.target_age'),
              roi: t('customer:diagram.section1.table.roi'),
              fundings_sum: t('customer:diagram.section1.table.findings_sum'),
              tariff: t('customer:diagram.section1.table.tariff'),
            }}
            customTableCell={{
              savings_rate: (
                <CellTextInput
                  value={state.savings_rate.toString()}
                  onTextChange={(value) =>
                    dispatch({
                      type: 'update_value',
                      payload: {
                        key: 'savings_rate',
                        value,
                      },
                    })
                  }
                  inTable
                  valueType="currency"
                />
              ),
              target_age: (
                <CellTextInput
                  value={state.target_age.toString()}
                  onTextChange={(value) =>
                    dispatch({
                      type: 'update_value',
                      payload: {
                        key: 'target_age',
                        value,
                      },
                    })
                  }
                  inTable
                />
              ),
              roi: (
                <CellSelectInput
                  tableCellProps={{
                    sx: {
                      maxWidth: 50,
                    },
                  }}
                  value={state.roi.toString()}
                  items={selectedRoiRange}
                  onSelect={(value) =>
                    dispatch({
                      type: 'update_value',
                      payload: {
                        key: 'roi',
                        value,
                      },
                    })
                  }
                  valueType="percent"
                  inTable
                />
              ),
              fundings_sum: (
                <CellTextInput
                  value={state.fundings_sum.toString()}
                  onTextChange={(value) =>
                    dispatch({
                      type: 'update_value',
                      payload: {
                        key: 'fundings_sum',
                        value,
                      },
                    })
                  }
                  valueType="currency"
                  inTable
                />
              ),
              tariff: (
                <CellSelectInput
                  value={state.tariff}
                  items={Tariffs}
                  onSelect={(value) => {
                    dispatch({
                      type: 'update_value',
                      payload: {
                        key: 'tariff',
                        value,
                      },
                    });
                  }}
                />
              ),
            }}
          />
        </Stack>
      </Section>
      <Section>
        <Stack gap="19px">
          <Typography variant="subtitle2">
            {t('customer:diagram.section2.title')}
          </Typography>
          <TableKeyValue
            data={[
              [
                t('customer:diagram.section2.table.finalCapital'),
                getTotal(savingsData[1]).format(),
              ],
              [
                t('customer:diagram.section2.table.contributions'),
                getTotal(savingsData[2]).format(),
              ],
              [
                t('customer:diagram.section2.table.totalWithdrawals'),
                getTotal(savingsData[5]).format(),
              ],
              [
                t('customer:diagram.section2.table.totalReturns'),
                getTotal(savingsData[3]).format(),
              ],
              [
                t('customer:diagram.section2.table.taxFreeCapital'),
                getTotal(savingsData[4]).format(),
              ],
            ]}
          />
        </Stack>
      </Section>
      <Section>
        <Stack gap="19px">
          <Typography variant="subtitle2">
            {t('customer:diagram.section3.title')}
          </Typography>
          {!!savingsData && (
            <BarChart
              height={536}
              margin={{ bottom: 102 }}
              skipAnimation
              grid={{ horizontal: true }}
              borderRadius={8}
              slotProps={{
                legend: {
                  position: { vertical: 'bottom', horizontal: 'middle' },
                  padding: { top: 100 },
                  itemGap: 48,
                },
              }}
              series={[
                {
                  data: savingsData[1],
                  label: t('customer:diagram.section3.values.total'),
                  color: '#1AA0B0',
                  stack: 'A',
                },
                {
                  data: savingsData[2],
                  label: t('customer:diagram.section3.values.contributions'),
                  color: '#00ABEA',
                  stack: 'A',
                },
                {
                  data: savingsData[3],
                  label: t('customer:diagram.section3.values.taxFree'),
                  color: '#85D7F5',
                  stack: 'A',
                },
                {
                  data: savingsData[4],
                  label: t('customer:diagram.section3.values.withdrawal'),
                  color: '#D33F33',
                  stack: 'A',
                },
              ]}
              xAxis={[
                {
                  scaleType: 'band',
                  data: savingsData[0],
                  disableLine: true,
                  tickLabelStyle: {
                    display: 'none',
                  },
                },
              ]}
              yAxis={[
                {
                  disableLine: true,
                  disableTicks: true,
                  tickNumber: 4,
                },
              ]}
            />
          )}
        </Stack>
      </Section>
      <Section>
        <Typography variant="subtitle2">
          {t('customer:diagram.section4.title')}
        </Typography>
        <TableGenerator head={SavingsTableHeader} values={SavingsTableRows} />
      </Section>
    </Stack>
  );
};

export default DiagramView;
